import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";

export const teacherCreateAction=createAsyncThunk(
    'teacher/create',async(reqBody,thunkAPI)=>{
        try {
            const response = await Api.post('school/teacher/create',reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

    export const teacherListAction=createAsyncThunk(
        'teacher/list',async(reqBody,thunkAPI)=>{
            try {
                const response = await Api.get(`school/teacher/list?search=${reqBody?.searchValue}&department=${reqBody?.selectedDepartment}`)
               
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })

        export const teacherDetailAction=createAsyncThunk(
            'teacher/details',async(reqBody,thunkAPI)=>{
                try {
                    const response = await Api.get(`school/teacher/details?id=${reqBody}`)
                   
                    return response.data  
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })

            export const teacherDeleteAction=createAsyncThunk(
                'teacher/delete',async(reqBody,thunkAPI)=>{
                    try {
                        const response = await Api.delete(`school/teacher/delete?id=${reqBody}`)
                       
                        return response.data  
                    } catch (error) {
                        return thunkAPI.rejectWithValue(error.response.data);
                    }
                })

                export const teacheResetPassAction=createAsyncThunk(
                    'teacher/resetpass',async(reqBody,thunkAPI)=>{
                        try {
                            const response = await Api.put(`school/teacher/reset-password`,reqBody)
                           
                            return response.data  
                        } catch (error) {
                            return thunkAPI.rejectWithValue(error.response.data);
                        }
                    })
            

                export const teacherSuspendAction=createAsyncThunk(
                    'teacher/suspend',async(reqBody,thunkAPI)=>{
                        try {
                            const response = await Api.post(`school/teacher/suspend`,reqBody)
                           
                            return response.data  
                        } catch (error) {
                            return thunkAPI.rejectWithValue(error.response.data);
                        }
                    })



                export const teacherDailyUsageAction=createAsyncThunk(
                    'teacher/dailyUsage',async(reqBody,thunkAPI)=>{
                        try {
                            const response = await Api.get(`school/teacher/active-hours?id=${reqBody.id}&startOfWeek=${reqBody?.date.startOfWeek}&endOfWeek=${reqBody?.date?.endOfWeek}`)
                           
                            return response.data  
                        } catch (error) {
                            return thunkAPI.rejectWithValue(error.response.data);
                        }
                    })

                    

                    export const teacherEditAction=createAsyncThunk(
                        'teacher/edit',async(reqBody,thunkAPI)=>{
                            try {
                                const response = await Api.put(`school/teacher/edit`,reqBody)
                               
                                return response.data  
                            } catch (error) {
                                return thunkAPI.rejectWithValue(error.response.data);
                            }
                        })


                        export const teacherEditProfileSettingsAction=createAsyncThunk(
                            'teacher/editProfile',async(reqBody,thunkAPI)=>{
                                try {
                                    const response = await Api.put(`school/auth/teacher/edit`,reqBody)
                                   
                                    return response.data  
                                } catch (error) {
                                    return thunkAPI.rejectWithValue(error.response.data);
                                }
                            })
    

                        

                        export const resetTeacherAction = createAction('browse/reset');


                        export const multiTeachercreateAction = createAsyncThunk(
                            'teacher/multiTeacher', async (reqBody, thunkAPI) => {
                                try {
                                    const response = await Api.post('school/teacher/csv', reqBody)
                                    return response.data
                                } catch (error) {
                                    return thunkAPI.rejectWithValue(error.response.data);
                                }
                            })
          


        