import { createSlice } from "@reduxjs/toolkit";
import { AddCollectionToclassAction, ClassDeleteAction, classRemoveAction, classcreateAction, classdetailsAction, classlistAction, resetClassAction } from "../Action/ClassAction";

const initialState = {
    classData: [],
    createClassStatus:'idle',
    classRemoveStatus:"idle",
    ClassDeleteStatus:"idle",
    AddCollectionToclassStatus:"idle",
    classDetails:{}
}

const classSlice = createSlice({
    name: 'class',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(classlistAction.fulfilled, (state, action) => {
                state.classData = action?.payload?.data;
            })

            .addCase(classcreateAction.fulfilled, (state, action) => {
                state.createClassStatus = 'succeeded';
            })
            .addCase(classdetailsAction.fulfilled, (state, action) => {
                state.classDetails = action?.payload?.data;
            })

            .addCase(classRemoveAction.fulfilled, (state, action) => {
                state.classRemoveStatus = 'succeeded';
            })

            .addCase(ClassDeleteAction.fulfilled, (state, action) => {
                state.ClassDeleteStatus = 'succeeded';
            })

            .addCase(AddCollectionToclassAction.fulfilled, (state, action) => {
                state.AddCollectionToclassStatus = 'succeeded';
            })
            
            
            .addCase(resetClassAction, () => initialState);
 
            
            

    },
});

export default classSlice.reducer;


