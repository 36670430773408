import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";

export const getBrowseCatagoryAction = createAsyncThunk(
    'browse/catagory', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`school/content/list?search=${reqBody?.searchValue}&categoryId=${reqBody?.categories}&group=${reqBody?.selectedGroup}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

export const browseDetailAction = createAsyncThunk(
    'browse/details', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`school/content/details?id=${reqBody.id}&search=${reqBody?.searchValue}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })
export const browseAddToCollectionAction = createAsyncThunk(
    'browse/addToCollection', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('school/content/collection-add', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



    export const VideoviewAction = createAsyncThunk(
        'browse/View', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.post('school/content/view', reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    

export const browseCategoryDetailAction = createAsyncThunk(
    'browse/browseCatagory', async (reqBody, thunkAPI) => {
        try {

            const response = await Api.get(`school/content/category?id=${reqBody.id}&search=${reqBody?.searchValue}&videoId=${reqBody?.videoId}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

// export const browseCategoryDetailAction = createAsyncThunk(
//     'browse/browseCategory',
//     async (reqBody, thunkAPI) => {
//         try {
//             const params = new URLSearchParams();
//             if (reqBody.id) params.append('id', reqBody.id);
//             if (reqBody.searchValue) params.append('search', reqBody.searchValue);
//             if (reqBody.videoId) params.append('videoId', reqBody.videoId ? reqBody.videoId : "");
            
//             const response = await Api.get(`school/content/category?${params.toString()}`);
//             return response.data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error.response.data);
//         }
//     }
// );



export const browseCategoryDetailTestAction = createAsyncThunk(
    'browse/browseCatagorytest', async (reqBody, thunkAPI) => {
        try {

            const response = await Api.get(`school/content/test?categoryId=${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })
    


export const browseCategoryDetailTestDetailAction = createAsyncThunk(
    'browse/browseCatagorytestDetail', async (reqBody, thunkAPI) => {
        try {

            const response = await Api.get(`school/content/test/details?id=${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })
    

export const resetBrowseAction = createAction('browse/reset');

export const allCategoryList = createAsyncThunk(
    'school/category', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`school/category/list`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })