import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";
import { constructQueryString } from "../../Util/HelperFunction";



export const studentListAction = createAsyncThunk(
    'student/list', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`school/student/list?search=${reqBody?.searchValue}&group=${reqBody?.selectedDepartment}`)

            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



    export const parentListAction = createAsyncThunk(
        'parent/list', async (reqBody, thunkAPI) => {
            try {
                const query = constructQueryString(reqBody)
                const response = await Api.get(`school/parent/list?${query}`)
    
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    
    

    export const studentDailyUsageAction=createAsyncThunk(
        'school/dailyUsage',async(reqBody,thunkAPI)=>{
            try {
                const response = await Api.get(`school/student/active-hours?id=${reqBody.id}&startOfWeek=${reqBody?.date.startOfWeek}&endOfWeek=${reqBody?.date?.endOfWeek}`)
               
                return response.data  
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })


        export const parentDailyUsageAction=createAsyncThunk(
            'parent/dailyUsage',async(reqBody,thunkAPI)=>{
                try {
                    const response = await Api.get(`school/parent/active-hours?id=${reqBody.id}&startOfWeek=${reqBody?.date.startOfWeek}&endOfWeek=${reqBody?.date?.endOfWeek}`)
                   
                    return response.data  
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
    

export const studentDetailAction = createAsyncThunk(
    'student/detail', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`school/student/details?id=${reqBody}`)

            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


export const parentDetailAction = createAsyncThunk(
    'parent/detail', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`school/parent/details?id=${reqBody}`)

            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



    

export const studentAddToClassAction = createAsyncThunk(
    'student/addToClass', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('school/student/add-class', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



export const studentAddToteacherClass = createAsyncThunk(
    'student/addToteacher', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('school/student/add-to-teacher', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


    export const studentShareCollectionAction = createAsyncThunk(
        'student/shareCollection', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.post('school/student/share-collection', reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    

        export const studentAddAction = createAsyncThunk(
            'student/addToclass', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.post('school/student/assign-classes', reqBody)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
        

        
        export const SetStudentPasswordAction = createAsyncThunk(
        'SetStudentPasswordAction', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.post('student/auth/set-password',reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
    
        })



        export const SetParentPasswordAction = createAsyncThunk(
            'SetParentPasswordAction', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.put('student/auth/reset',reqBody)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
        
            })
    
    
    export const studentResetPasswordAction = createAsyncThunk(
        'student/resetpassword', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.put('school/student/reset-password', reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    

        export const parentResetPasswordAction = createAsyncThunk(
            'parent/resetpassword', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.post('school/parent/reset-password', reqBody)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
        

    
        export const studentDeleteAction=createAsyncThunk(
            'student/delete',async(reqBody,thunkAPI)=>{
                try {
                    const response = await Api.delete(`school/student/delete?id=${reqBody}`)
                   
                    return response.data  
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })

            export const multiStudentcreateAction = createAsyncThunk(
                'student/multiStudent', async (reqBody, thunkAPI) => {
                    try {
                        const response = await Api.post('school/student/csv', reqBody)
                        return response.data
                    } catch (error) {
                        return thunkAPI.rejectWithValue(error.response.data);
                    }
                })
   
            export const resetStudentAction = createAction('student/reset');
   