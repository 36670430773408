import { createSlice } from "@reduxjs/toolkit";
import { resetStudentAction, studentDailyUsageAction, studentAddToClassAction, studentDeleteAction, studentDetailAction, studentListAction, studentResetPasswordAction, studentShareCollectionAction, studentAddAction, studentAddToteacherClass, SetStudentPasswordAction, multiStudentcreateAction, SetParentPasswordAction, parentListAction, parentDetailAction, parentDailyUsageAction, parentResetPasswordAction } from "../Action/StudentAction";


const initialState = {
   students:{},
   parents:{},
   studentDetail:{},
   parentDetail:{},
   studentAddToClassStatus:"idle",
   studentResetPasswordStatus:"idle",
   studentDeleteStatus:"idle",
   studentAddStatus:"idle",
   studentShareCollectionStatus:"idle",
   studentAddToteacherStatus:"idle",
   SetStudentPasswordStatus:"idle",
   multiStudentcreateStatus:"idle",
   SetParentPasswordStatus:"idle",
   parentResetStatus:"idle",
   studentDailyUsage:{},
   parentDailyUsage:{}
}

const studentSlice = createSlice({
    name: 'student',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(studentListAction.fulfilled, (state, action) => {
                state.students = action?.payload?.data;
            })    

            .addCase(studentDetailAction.fulfilled, (state, action) => {
                state.studentDetail = action?.payload?.data;
            })   

            .addCase(parentDetailAction.fulfilled, (state, action) => {
                state.parentDetail = action?.payload?.data;
            })   

            
            
            .addCase(studentAddToClassAction.fulfilled, (state, action) => {
                state.studentAddToClassStatus = 'succeeded';
            })   
            
            .addCase(studentResetPasswordAction.fulfilled, (state, action) => {
                state.studentResetPasswordStatus = 'succeeded';
            })   
            
            .addCase(studentDeleteAction.fulfilled, (state, action) => {
                state.studentDeleteStatus = 'succeeded';
            })   

            .addCase(studentDailyUsageAction.fulfilled, (state, action) => {
                state.studentDailyUsage = action?.payload?.data;
            })   

            .addCase(parentDailyUsageAction.fulfilled, (state, action) => {
                state.parentDailyUsage = action?.payload?.data;
            })   

            
            .addCase(parentResetPasswordAction.fulfilled, (state, action) => {
                state.parentResetStatus = action?.payload?.data;
            })   

            

            
            .addCase(studentShareCollectionAction.fulfilled, (state, action) => {
                state.studentShareCollectionStatus = 'succeeded';
            })   

            .addCase(studentAddAction.fulfilled, (state, action) => {
                state.studentAddStatus = 'succeeded';
            })   

            
            .addCase(studentAddToteacherClass.fulfilled, (state, action) => {
                state.studentAddToteacherStatus = 'succeeded';
            })   


            .addCase(parentListAction.fulfilled, (state, action) => {
                state.parents = action?.payload?.data;
            })   

            

            .addCase(SetStudentPasswordAction.fulfilled, (state, action) => {
                state.SetStudentPasswordStatus = 'succeeded';
            })   

            .addCase(multiStudentcreateAction.fulfilled, (state, action) => {
                state.multiStudentcreateStatus = 'succeeded';
            })   

            .addCase(SetParentPasswordAction.fulfilled, (state, action) => {
                state.SetParentPasswordStatus = 'succeeded';
            })   


            
            .addCase(resetStudentAction, () => initialState);
            
            
    },
});

export default studentSlice.reducer;




