import React, { createContext, useState } from "react";

// Create ProfileContext
export const SocketContext = createContext();


export const SocketProvider = ({ children }) => {
const [socketData, setSocketData] = useState(null);

return (
<SocketContext.Provider value={{ socketData, setSocketData }}>
{children}
</SocketContext.Provider>
);
};