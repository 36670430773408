import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";
import { constructQueryString } from "../../Util/HelperFunction";

export const dashboardRegistrationsAction = createAsyncThunk(
    'dashboard/Regis', async (reqBody, thunkAPI) => {
        try {
            const query = constructQueryString(reqBody)
            const response = await Api.get(`/school/auth/user-stats?${query}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


    export const dashboardViewByWeekAction = createAsyncThunk(
        'dashboard/ViewByweek', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get(`/school/auth/viewby_week`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
 
    export const dashboardActivityAction = createAsyncThunk(
        'dashboard/Acti', async (reqBody, thunkAPI) => {
            try {
                const query = constructQueryString(reqBody)
                const response = await Api.get(`/school/auth/dashboard?${query}`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    



        export const dashboardVideoAction = createAsyncThunk(
            'dashboard/Video', async (reqBody, thunkAPI) => {
                try {
                    const query = constructQueryString(reqBody)
                    const response = await Api.get(`/school/auth/video_view?${query}`)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
        
    



        export const dashboardCategoryAction = createAsyncThunk(
            'dashboard/category', async (reqBody, thunkAPI) => {
                try {
                    const query = constructQueryString(reqBody)
                    const response = await Api.get(`/school/auth/category_view?${query}`)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
        
    



