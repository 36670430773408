import React, { Suspense, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Sidebar from "./Sidebar";

const PrivateRoutesSuspense = ({ children }) => {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <div>
        <div className="main-wrap ">
          <div className={show ? "left-side hide" : "left-side "}>
            <Sidebar
              isActive={isActive}
              setIsActive={setIsActive}
              show={show}
              setShow={setShow}
            />
          </div>
          <div className='top-fix-sec'>

            <Suspense fallback={
              <div className='spin-loader'
              >
                <Spinner animation="grow" style={{ background: "#d200a5" }} />
                <Spinner animation="grow" style={{ background: "#d200a5" }} />
                <Spinner animation="grow" style={{ background: "#d200a5" }} />
                <Spinner animation="grow" style={{ background: "#2800ea" }} />
                <Spinner animation="grow" style={{ background: "#2800ea" }} />
                <Spinner animation="grow" style={{ background: "#2800ea" }} />
                <Spinner animation="grow" style={{ background: "#bbc6ff" }} />
              </div>}>
              <div className={show ? "right-side-cmn hide" : "right-side-cmn "}>
                {children}
              </div>
            </Suspense>

          </div>
        </div>
      </div>
    </>

  );
};

export default PrivateRoutesSuspense;



