import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";

export const classcreateAction=createAsyncThunk(
    'class/create',async(reqBody,thunkAPI)=>{
        try {
            const response = await Api.post('school/class/create',reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


    export const classRemoveAction=createAsyncThunk(
        'class/remove',async(reqBody,thunkAPI)=>{
            try {
                const response = await Api.post('school/student/remove-class',reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    


        export const AddCollectionToclassAction=createAsyncThunk(
            'class/addCollection',async(reqBody,thunkAPI)=>{
                try {
                    const response = await Api.post('school/class/add-collection',reqBody)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
        


   

    export const classlistAction=createAsyncThunk(
        'class/list',async(reqBody,thunkAPI)=>{
            try {
                const response = await Api.get(`school/class/list?search=${reqBody?.searchValue}&group=${reqBody?.selectedGrpup}&year=${reqBody?.selectedClassYear}`)
               
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })

    



    export const classdetailsAction=createAsyncThunk(
            'class/details',async(reqBody,thunkAPI)=>{
                try {
                    const response = await Api.get(`/school/class/details?id=${reqBody}`)
                    
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })

            export const resetClassAction = createAction('browse/reset');



    

    export const ClassDeleteAction=createAsyncThunk(
        'class/delete',async(reqBody,thunkAPI)=>{
            try {
                const response = await Api.delete(`/school/class/delete?id=${reqBody?.id}`)
                
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })


    

        //     export const updateAction=createAsyncThunk(
        //         'class/update',async(reqBody,thunkAPI)=>{
        //             try {
        //                 const response = await Api.put(`/class/update`,reqBody)
                        
        //                 return response.data
        //             } catch (error) {
        //                 return thunkAPI.rejectWithValue(error.response.data);
        //             }
        //         })


        // export const suspendAction=createAsyncThunk(
        //     'class/suspend',async(reqBody,thunkAPI)=>{
        //         try {
        //             const response = await Api.post('/class/suspend',reqBody)
        //             return response.data
        //         } catch (error) {
        //             return thunkAPI.rejectWithValue(error.response.data);
        //         }
        //     })  
                