import { createSlice } from "@reduxjs/toolkit";
import {dashboardActivityAction, dashboardCategoryAction, dashboardRegistrationsAction, dashboardVideoAction, dashboardViewByWeekAction } from "../Action/DashboardAction";



const initialState = {
    dashboardRegistrationsStatus:"idle",
    dashboardCategoryStatus:"idle",
    dashboardVideoStatus:"idle",
    dashboardActivityStatus:"idle",
    dashboardViewByWeekStatus:"idle"
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(dashboardRegistrationsAction.fulfilled, (state, action) => {
                state.dashboardRegistrationsStatus = action?.payload?.data;  
            })     
             

            .addCase(dashboardCategoryAction.fulfilled, (state, action) => {
                state.dashboardCategoryStatus = action?.payload?.data;  
            })     
             

            .addCase(dashboardVideoAction.fulfilled, (state, action) => {
                state.dashboardVideoStatus = action?.payload?.data;  
            })   
            
            .addCase(dashboardActivityAction.fulfilled, (state, action) => {
                state.dashboardActivityStatus = action?.payload?.data;  
            })     
             
            .addCase(dashboardViewByWeekAction.fulfilled, (state, action) => {
                state.dashboardViewByWeekStatus = action?.payload?.data;  
            })     
             

            


    },
});

export default dashboardSlice.reducer;








